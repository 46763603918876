import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import { useAppSelector } from '../../../hooks'
import { selectCurrentPlaylistSlug } from '../../../selectors/current-play-selectors'
import { LeaderboardCell, selectMatchLeaderboardData } from '../../../selectors/match-selectors'
import TrackBadge from '../../widgets/TrackBadge'

type Props = { matchSlug: string; isShowGuestPlayers: boolean }

const MatchLeaderboard = ({ matchSlug: compoundMatchSlug, isShowGuestPlayers }: Props) => {
  const leaderboardData = useAppSelector(selectMatchLeaderboardData(compoundMatchSlug))
  const { colLabels, rowLabels, dataRows } = leaderboardData
  const currentPlaylistSlug = useAppSelector(selectCurrentPlaylistSlug)

  let inDefaultOpen = false
  const initialRowState = rowLabels.map(({ slug, trackInfo }, index) => {
    if (!trackInfo) {
      inDefaultOpen = slug === currentPlaylistSlug
    }
    return inDefaultOpen
  })
  const [playlistRowState, setPlaylistRowState] = useState<boolean[]>(initialRowState)

  const togglePlaylist = (playlistRow: number) => {
    const newState = !playlistRowState[playlistRow]
    const newRowState = [...playlistRowState]
    newRowState[playlistRow] = newState
    for (let row = playlistRow + 1; row < rowLabels.length; row++) {
      const { trackInfo } = rowLabels[row]
      if (!trackInfo) {
        break
      }
      newRowState[row] = newState
    }
    setPlaylistRowState(newRowState)
  }
  const getRowLabel = (rowIndex: number) => {
    const { title, trackInfo } = rowLabels[rowIndex]
    if (!!trackInfo && !playlistRowState[rowIndex]) {
      return null
    }
    return (
      <td className="sticky-label">
        {trackInfo ? (
          <TrackBadge trackInfo={trackInfo} />
        ) : (
          <div onClick={() => togglePlaylist(rowIndex)} className="playlistInfo">
            {title}
          </div>
        )}
      </td>
    )
  }
  const getHeader = () => {
    const badgeClass = cx('playerBadge', { guestName: isShowGuestPlayers })
    return (
      <tr>
        <th className="sticky-header corner"></th>
        {colLabels.map((colLabel, index) => (
          <th key={index} className="sticky-header">
            <div className="playerScore">
              <div className={badgeClass}>{colLabel.title}</div>
              <div className="matchRank">{index + 1}</div>
              <div className="scoreWrapper">
                <div className={cx('score', { [colLabel.scoreRank || '']: true })}>
                  {colLabel.score}
                </div>
              </div>
            </div>
          </th>
        ))}
      </tr>
    )
  }
  const getBody = () => {
    return dataRows.map((row, rowIndex) => {
      const isTrack = !!rowLabels[rowIndex].trackInfo
      if (!playlistRowState[rowIndex] && isTrack) {
        return null
      }
      return (
        <tr key={rowIndex}>
          {getRowLabel(rowIndex)}
          {row.map((cell: LeaderboardCell, colIndex: number) => {
            return (
              <td key={colIndex}>
                <div className={cx('playerScore', { isPlaylist: !isTrack })}>
                  {cell.score > 0 && (
                    <div className={cx('score', { [cell.scoreRank || '']: true })}>
                      {cell.score}
                    </div>
                  )}
                </div>
              </td>
            )
          })}
        </tr>
      )
    })
  }

  return (
    <div className="leaderboardWrapper">
      <div className="table-container">
        <table className="table">
          <thead>{getHeader()}</thead>
          <tbody>{getBody()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default MatchLeaderboard
