import React from 'react'
import cx from 'classnames'

import { useAppSelector } from '../../hooks'
import { selectCurrentPlayStatus } from '../../selectors/current-play-selectors'

type Props = {
  playerIndex: number
}
const MovingLaneScore = ({ playerIndex }: Props) => {
  const playStatus = useAppSelector(selectCurrentPlayStatus(playerIndex))
  const { score, maxScore, runningScoreRank } = playStatus
  const [maxWidth, setMaxWidth] = React.useState(0)
  const [scoreWidth, setScoreWidth] = React.useState(0)
  const setWrapperRef = (elem: HTMLDivElement) => {
    if (elem) {
      setMaxWidth(elem.clientWidth)
    }
  }
  const setScoreRef = (elem: HTMLDivElement) => {
    if (elem) {
      setScoreWidth(elem.clientWidth)
    }
  }
  const padding = 9
  const scorePercentage = -scoreWidth / 2 + ((maxWidth - padding) * score) / maxScore
  const style = { left: `${scorePercentage}px` }
  const classOptions = {
    active: true,
    [`${runningScoreRank || 'none'}`]: true,
  }
  const scoreClassName = cx('scoreView', classOptions)

  return (
    <div className={`lane${playerIndex}`} ref={setWrapperRef}>`
      <div style={style} className={scoreClassName} ref={setScoreRef}>
        {score}
      </div>
      <div className="scoreCountersWrapper"></div>
    </div>
  )
}

export default MovingLaneScore
