import { RootState } from '../reducers'
import {
  ActiveMatchInfo,
  GuestPlayer,
  InvitePath,
  MatchInfo,
  MatchPath,
  MatchStatus,
  MatchStatusMap,
  MinimalPlaylistInfo,
  PlayerStatus,
  PlayerStatusMap,
  PlaylistInfoMap,
  PlayStatus,
  PlayStatusMap,
  ScoreCounters,
  TrackStatsMap,
  TopScoreStat,
  ScoreRank,
  TrackInfo,
} from '../types'
import { selectOwnedMatchSlug, selectTrackInfo } from './blaster-peer-selectors'
import {
  selectActiveMatches,
  selectArchivedMatches,
  selectCurrentUsername,
  selectPlayerVisibility,
} from './session-selectors'
import { pathOr } from 'ramda'
import {
  defaultMatchInfo,
  defaultMatchStatus,
  defaultPlayerScore,
  defaultPlaylistInfo,
  defaultScoreCounters,
  defaultTrackInfo,
  HOST_VISIBILITY_HIDE,
} from '../constants/constants'
import Util from '../util/util'

type PlayerPath = {
  matchOwner: string
  matchSlug: string
  username: string
  player: string
}
type PlayerTrackPath = {
  matchOwner: string
  matchSlug: string
  username: string
  player: string
  trackSlug: string
}

export const selectMatchTitle = (matchOwner: string, matchSlug: string) => (state: RootState) => {
  return pathOr('', ['matchStatus', matchOwner, matchSlug, 'info', 'title'], state)
}

export const selectOwnerMatchStatuses =
  (matchOwner: string) =>
  (state: RootState): MatchStatusMap =>
    pathOr({}, ['matchStatus', matchOwner], state)

export const selectMatchStatus =
  (matchOwner: string, matchSlug: string) =>
  (state: RootState): MatchStatus =>
    pathOr(defaultMatchStatus(), ['matchStatus', matchOwner, matchSlug], state)

export const selectMatchInfo =
  (matchOwner: string, matchSlug: string) =>
  (state: RootState): MatchInfo =>
    pathOr(defaultMatchInfo(), ['matchStatus', matchOwner, matchSlug, 'info'], state)

export const selectPlaylistMap =
  (compoundMatchSlug: string) =>
  (state: RootState): PlaylistInfoMap => {
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    return pathOr({}, ['matchStatus', matchOwner, matchSlug, 'info', 'playlists'], state)
  }

export const selectGuestPlayers =
  (compoundMatchSlug: string) =>
  (state: RootState): GuestPlayer[] => {
    const currentUsername = selectCurrentUsername(state)
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      info: { hostVisibility },
    } = matchStatus
    const currentUserPlayerScores: PlayerStatusMap = pathOr(
      {},
      ['players', currentUsername],
      matchStatus
    )
    const currentUserGuests: GuestPlayer[] = []
    const playerIds = Object.keys(currentUserPlayerScores)
    playerIds.forEach((playerId: string) => {
      const playerScore = currentUserPlayerScores[playerId]
      if (
        playerIds.length > 1 &&
        hostVisibility === HOST_VISIBILITY_HIDE &&
        playerId === currentUsername
      ) {
        return
      }
      currentUserGuests.push({ slug: playerId, name: playerScore.name || '' })
    })
    return currentUserGuests
  }

export type LeaderboardCell = {
  slug: string
  row: number
  col: number
  title: string
  score: number
  scoreRank?: ScoreRank
  trackInfo?: TrackInfo
}
export type LeaderboardRow = LeaderboardCell[]
export type LeaderboardData = {
  colLabels: LeaderboardRow
  rowLabels: LeaderboardRow
  dataRows: LeaderboardRow[]
}

export const selectMatchLeaderboardData =
  (compoundMatchSlug: string) =>
  (state: RootState): LeaderboardData => {
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      info: { hostVisibility, playlistOrder, playlists },
      leaderboard,
      players: playerScores,
    } = matchStatus
    const colLabels: LeaderboardRow = []
    const { isShowGuestPlayers, isShowHostPlayer, isShowUserPlayers } =
      selectPlayerVisibility(state)
    const isShowHost = isShowHostPlayer && hostVisibility !== HOST_VISIBILITY_HIDE
    const filteredPlayerIds = leaderboard.reduce(
      (acc: { username: string; playerId: string }[], compoundPlayerSlug: string) => {
        const [username, playerId] = compoundPlayerSlug.split('/')
        if (username === matchOwner) {
          if (playerId === matchOwner) {
            if (!isShowHost) {
              return acc
            }
          } else if (!isShowGuestPlayers) {
            return acc
          }
        } else if (!isShowUserPlayers) {
          return acc
        }
        return [...acc, { username, playerId }]
      },
      []
    )
    const scoredTracks = filteredPlayerIds.reduce((acc, { username, playerId }) => {
      const { trackScores } = playerScores[username][playerId]
      Object.keys(trackScores).forEach((trackSlug) => {
        const { topScore } = trackScores[trackSlug]
        if (topScore > 0) {
          acc.add(trackSlug)
        }
      })
      return acc
    }, new Set<string>())
    filteredPlayerIds.forEach(({ playerId, username }, index) => {
      const { name, matchScore } = playerScores[username][playerId]
      colLabels.push({
        row: 0,
        col: index + 1,
        slug: playerId,
        title: name || playerId,
        score: matchScore.topScore,
        scoreRank: matchScore.topScoreRank,
      })
    })
    const rowLabels: LeaderboardRow = []
    const dataRows: LeaderboardRow[] = []
    let rowIndex = 1 // 0th row is column headers
    playlistOrder.forEach((playlistSlug, playlistIndex) => {
      const { title, trackOrder } = playlists[playlistSlug]
      rowLabels.push({
        row: rowIndex++,
        col: 0,
        slug: playlistSlug,
        title,
        score: 0,
      })
      const playlistRow: LeaderboardRow = filteredPlayerIds.map(({ username, playerId }, index) => {
        const { playlistScores } = playerScores[username][playerId]
        const playlistScore = playlistScores[playlistSlug]
        return {
          row: 0,
          col: index + 1,
          slug: playerId,
          title: '',
          score: playlistScore?.topScore || 0,
          scoreRank: playlistScore?.topScoreRank,
        }
      })
      dataRows.push(playlistRow)

      trackOrder.forEach((trackSlug, index) => {
        if (scoredTracks.has(trackSlug)) {
          const trackInfo = selectTrackInfo(matchOwner, trackSlug)(state) || defaultTrackInfo
          rowLabels.push({
            row: rowIndex++,
            col: 0,
            slug: trackSlug,
            title: trackInfo.title,
            trackInfo,
            score: 0,
          })
          const trackRow: LeaderboardRow = filteredPlayerIds.map(
            ({ username, playerId }, index) => {
              const { trackScores } = playerScores[username][playerId]
              const trackScore = trackScores[trackSlug]
              return {
                row: rowIndex++,
                col: index + 1,
                slug: trackSlug,
                title: '',
                score: trackScore?.topScore || 0,
                scoreRank: trackScore?.topScoreRank,
              }
            }
          )
          dataRows.push(trackRow)
        }
      })
    })
    return { colLabels, dataRows, rowLabels }
  }

export const selectPlayerScore =
  ({ matchOwner, matchSlug, player, username }: PlayerPath) =>
  (state: RootState): PlayerStatus =>
    pathOr(
      defaultPlayerScore(),
      ['matchStatus', matchOwner, matchSlug, 'players', username, player],
      state
    )

const compareScores = (
  { topScore: topScoreA }: TopScoreStat,
  { topScore: topScoreB }: TopScoreStat
) => {
  return topScoreB - topScoreA
}

export const selectTrackStats =
  ({ matchOwner, matchSlug }: MatchPath) =>
  (state: RootState): TrackStatsMap => {
    const { isShowUserPlayers, isShowHostPlayer } = selectPlayerVisibility(state)
    const allTrackStats: TrackStatsMap = {}
    const matchStatus: MatchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      players,
      info: { hostVisibility },
    } = matchStatus
    const isHideHostHost = !isShowHostPlayer || hostVisibility === HOST_VISIBILITY_HIDE
    Object.keys(players).forEach((username) => {
      const isUserPlayer = username !== matchOwner
      const playerIds = Object.keys(players[username])

      playerIds.forEach((playerId) => {
        if (isUserPlayer) {
          if (!isShowUserPlayers || playerId !== username) {
            return // TODO: allow multiple remote guests?
          }
        }
        if (playerId === matchOwner && isHideHostHost) {
          return
        }
        const playerName: string = pathOr(playerId, [username, playerId, 'name'], players)
        const playerTrackScores: PlayStatusMap = pathOr(
          {},
          [username, playerId, 'trackScores'],
          players
        )
        Object.keys(playerTrackScores).forEach((trackSlug) => {
          const playerTrackScore = playerTrackScores[trackSlug] as PlayStatus
          const currTrackStat = allTrackStats[trackSlug]
          const { playerTopScores = [] } = currTrackStat || {}
          const { topScore, topScoreRank } = playerTrackScore
          const newStat = { playerName, playerId, topScore, topScoreRank }
          Util.insertIntoSortedArray(playerTopScores, newStat, compareScores)
          allTrackStats[trackSlug] = { trackSlug, username, playerTopScores }
        })
      })
    })

    return allTrackStats
  }

export const selectPlayerCountersForTrack =
  ({ matchOwner, matchSlug, player, username, trackSlug }: PlayerTrackPath) =>
  (state: RootState): ScoreCounters =>
    pathOr(
      defaultScoreCounters(),
      [
        'matchStatus',
        matchOwner,
        matchSlug,
        'players',
        username,
        player,
        'trackScores',
        trackSlug,
        'counters',
      ],
      state
    )

export const selectPlayerTopScoreForTrack =
  ({ matchOwner, matchSlug, username, player, trackSlug }: PlayerTrackPath) =>
  (state: RootState): number =>
    pathOr(
      0,
      [
        'matchStatus',
        matchOwner,
        matchSlug,
        'players',
        username,
        player,
        'trackScores',
        trackSlug,
        'topScore',
      ],
      state
    )

export const selectUserMatchesInfo = (state: RootState) => {
  const activeMatches = selectActiveMatches(state)
  const activeMatchInfo = activeMatches.map(({ matchOwner, matchSlug, inviteKey }) => {
    const matchInfo = selectMatchInfo(matchOwner, matchSlug)(state)
    return { id: matchInfo.slug, ...matchInfo, matchOwner, matchSlug, inviteKey, isActive: true }
  })
  const archivedMatches = selectArchivedMatches(state)
  const archivedMatchInfo = archivedMatches.map(
    ({ matchOwner, matchSlug, inviteKey }: InvitePath) => {
      const matchInfo = selectMatchInfo(matchOwner, matchSlug)(state)
      return { id: matchInfo.slug, ...matchInfo, matchOwner, matchSlug, inviteKey, isActive: false }
    }
  )
  return activeMatchInfo.concat(archivedMatchInfo)
  // TODO: how to handle multiple different invites to same match
  // const activeMatchSlugs = activeMatches.map(({ matchSlug }) => matchSlug)
  // const dedupedArchivedMatchInfo = archivedMatchInfo.filter(({ matchSlug }) => {
  //   return activeMatchSlugs.includes(matchSlug)
  // })
  // return activeMatchInfo.concat(dedupedArchivedMatchInfo)
}

export const selectMatchGroups = (state: RootState) => {
  const activeMatchSlugs = selectActiveMatches(state)
  const currentUsername = selectCurrentUsername(state)
  const ownedMatchSlug = selectOwnedMatchSlug(state)
  const hiddenMatchSlugs = selectArchivedMatches(state).map(
    ({ matchOwner, matchSlug }: ActiveMatchInfo) => `${matchOwner}/${matchSlug}`
  )
  const hostedMatchSlugs: string[] = []
  const playingMatchSlugs: string[] = []
  activeMatchSlugs.forEach(({ matchOwner, matchSlug }) => {
    const compoundMatchSlug = `${matchOwner}/${matchSlug}`
    if (compoundMatchSlug !== ownedMatchSlug) {
      const whichArray = currentUsername === matchOwner ? hostedMatchSlugs : playingMatchSlugs
      whichArray.push(compoundMatchSlug)
    }
  })
  return {
    ownedMatchSlug,
    hostedMatchSlugs,
    playingMatchSlugs,
    hiddenMatchSlugs,
  }
}

export const selectOwnedMatchInfo = (state: RootState) => {
  const compoundMatchSlug = selectOwnedMatchSlug(state)
  const [owner, matchSlug] = compoundMatchSlug.split('/')
  const matchInfo = selectMatchInfo(owner, matchSlug)(state)
  return matchInfo as MatchInfo
}

export const selectOwnedTrackCounters = (trackSlug: string) => (state: RootState) => {
  const compoundMatchSlug = selectOwnedMatchSlug(state)
  const [owner, matchSlug] = compoundMatchSlug.split('/')
  return selectPlayerCountersForTrack({
    matchOwner: owner,
    matchSlug,
    username: owner,
    player: owner,
    trackSlug,
  })(state)
}

export const selectMatchTracks = (compoundMatchSlug: string) => (state: RootState) => {
  const [owner, matchSlug] = compoundMatchSlug.split('/')
  const matchInfo = selectMatchInfo(owner, matchSlug)(state)
  const { playlistOrder = [], playlists = {} } = matchInfo
  const matchTracks: MinimalPlaylistInfo[] = []
  playlistOrder.forEach((playlistSlug, index) => {
    if (!(playlistSlug in playlists)) {
      console.log(`match ${compoundMatchSlug}: ${playlistSlug} missing from playlists`)
      return
    }
    const { trackOrder = [], slug, title, perTrackPlayLimit } = playlists[playlistSlug] // default if playlist has no tracks TODO: prevent selectors from returning malformed data!
    const tracks = trackOrder.map((trackSlug) => {
      const trackInfo = selectTrackInfo(owner, trackSlug)(state)
      return trackInfo || defaultTrackInfo
    })
    matchTracks.push({
      tracks,
      slug,
      title,
      perTrackPlayLimit,
      index,
    })
  })
  return matchTracks
}

export const selectPeerPlaylistInfo =
  (matchOwner: string, matchSlug: string, playlistSlug: string) => (state: RootState) =>
    pathOr(
      defaultPlaylistInfo,
      [matchOwner, matchSlug, 'info', 'playlists', playlistSlug],
      state.matchStatus
    )
