const gray100 = '#edf3f2'
const gray200 = '#e4e1e1'
// const gray300 = '#e8d7b1'
const gray350 = '#dadae2'
// const gray400 = '#bdb0b0'
const gray500 = '#bcafb0'
// const gray600 = '#727373'
const gray650 = '#434346'
// const gray700 = '#393939'
const gray800 = '#323233'
const gray900 = '#213131'

// const red000 = '#ff0000'
// const red100 = '#ec4bbb'
// const red200 = '#e63900'
// const red300 = '#a23f3f'
// const red400 = '#6f0418'

const yellow100 = '#939563'
// const yellow300 = '#fdd834'
const yellow400 = '#e8d7b1'

// const brown100 = 'burlywood'
const brown500 = '#382323' // '#4c4141'

// const green100 = '#72eacc'
// const green200 = '#89ea91'
// const green300 = 'lightseagreen'
const green500 = '#4d6f6f'

const blue100 = '#c9dbde'
const blue200 = '#15c2dc'
const blue250 = '#517575'
// const blue300 = '#141cb9'
const blue350 = '#3b4142'
const blue400 = '#385252'

const black = '#000000'
const white = '#ffffff'

export type ThemeCssVariable =
  | 'color-border-focus'
  | 'color-border-primary'
  | 'color-border-modal'
  | 'color-border-push-button'
  | 'color-border-tree-row-active'
  | 'color-bg-blast-button'
  | 'color-bg-button'
  | 'color-bg-controls'
  | 'color-bg-interaction-wrapper'
  | 'color-bg-input'
  | 'color-bg-list'
  | 'color-bg-modal'
  | 'color-bg-score-progress-bar'
  | 'color-bg-score-progress-bar-progress'
  | 'color-bg-sidebar'
  | 'color-bg-timer'
  | 'color-bg-tree-row-active'
  | 'color-bg-tree-row-player'
  | 'color-bg-tree-row-playlist'
  | 'color-bg-tree-row-track'
  | 'color-txt-button'
  | 'color-txt-button-footer'
  | 'color-txt-label'
  | 'color-txt-leaderboard-player'
  | 'color-txt-lyrics'
  | 'color-txt-score-summary'
  | 'color-txt-score-box-counter'
  | 'color-txt-score-progress'
  | 'color-txt-title'
  | 'color-txt-tree-match-title'
  | 'color-txt-tree-match-title-active'
  | 'color-hover-txt-button-footer'
  | 'color-hover-border-push-button'
  | 'color-border-button-hover'

type Theme = {
  name: string
  cssVars: { [key in ThemeCssVariable]: string }
}

const themes: Theme[] = [
  {
    name: 'Light',
    cssVars: {
      'color-border-focus': black,
      'color-border-primary': blue100,
      'color-border-modal': blue400,
      'color-border-push-button': gray200,
      'color-border-tree-row-active': gray100,
      'color-bg-blast-button': gray100,
      'color-bg-button': `${gray100}c0`,
      'color-bg-controls': white,
      'color-bg-interaction-wrapper': gray100,
      'color-bg-input': blue250,
      'color-bg-list': gray350, // gray100,
      'color-bg-modal': blue100,
      'color-bg-score-progress-bar': gray100,
      'color-bg-score-progress-bar-progress': gray350,
      'color-bg-sidebar': `${blue100}f0`,
      'color-bg-timer': gray100,
      'color-bg-tree-row-active': yellow400,
      'color-bg-tree-row-player': gray100,
      'color-bg-tree-row-playlist': gray200,
      'color-bg-tree-row-track': gray200,

      'color-txt-button': blue400,
      'color-txt-button-footer': brown500,
      'color-txt-label': brown500,
      'color-txt-leaderboard-player': brown500,
      'color-txt-lyrics': '#ffffff',
      'color-txt-score-summary': blue400,
      'color-txt-score-box-counter': blue400,
      'color-txt-score-progress': blue400,
      'color-txt-title': blue200,
      'color-txt-tree-match-title': blue200,
      'color-txt-tree-match-title-active': gray650,

      'color-hover-txt-button-footer': blue200,
      'color-hover-border-push-button': blue200,
      'color-border-button-hover': black,
    },
  },
  {
    name: 'Dark',
    cssVars: {
      'color-border-focus': white,
      'color-border-primary': blue400,
      'color-border-modal': blue100,
      'color-border-push-button': gray800,
      'color-border-tree-row-active': gray100,
      'color-bg-blast-button': gray900,
      'color-bg-button': `${gray650}f0`,
      'color-bg-controls': black,
      'color-bg-input': black,
      'color-bg-interaction-wrapper': gray650,
      'color-bg-list': black,
      'color-bg-modal': blue400,
      'color-bg-score-progress-bar': gray900,
      'color-bg-score-progress-bar-progress': green500,
      'color-bg-sidebar': `${blue400}f0`,
      'color-bg-timer': gray650,
      'color-bg-tree-row-active': black,
      'color-bg-tree-row-player': gray800,
      'color-bg-tree-row-playlist': gray650,
      'color-bg-tree-row-track': gray800,

      'color-txt-button': blue100,
      'color-txt-button-footer': blue100,
      'color-txt-label': blue100,
      'color-txt-leaderboard-player': gray500,
      'color-txt-lyrics': '#ffffff',
      'color-txt-score-summary': blue350,
      'color-txt-score-box-counter': blue350,
      'color-txt-score-progress': yellow100,
      'color-txt-title': blue200,
      'color-txt-tree-match-title': blue200,
      'color-txt-tree-match-title-active': '#8dcae3',
      'color-hover-txt-button-footer': blue200,
      'color-hover-border-push-button': blue200,
      'color-border-button-hover': gray100,
    },
  },
]

export default themes
