import cx from 'classnames'
import React, { useState } from 'react'

import { Pane, PaneClasses } from '../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { selectIsFocus } from '../../reducers/focusSlice'
import modalsSlice from '../../reducers/modalsSlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectCurrentGamers } from '../../selectors/current-play-selectors'
import { selectMatchTitle } from '../../selectors/match-selectors'
import {
  selectAudienceFlags,
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectPlayerVisibility,
} from '../../selectors/session-selectors'
import keyBindings from '../../services/KeyBindings'
import { onModalContainerRef } from '../../util/scrolling'
import Match from '../social/Match'
import CloseIcon from '../widgets/CloseIcon'
import ModalBackdrop from './ModalBackdrop'
import GuestPlayers from './match-info/GuestPlayers'
import { CheckboxWithLabel } from '../widgets/TextInputWithLabel'
import MatchLeaderboard from './leaderboard/MatchLeaderboard'

const LeaderboardModal = () => {
  const dispatch = useAppDispatch()
  const isCurrFocus = useAppSelector(selectIsFocus(Pane.STATUS))
  const currentMatchSlug = useAppSelector(selectCurrentMatchSlug)
  const [matchOwner, matchSlug] = currentMatchSlug.split('/')
  const matchTitle = useAppSelector(selectMatchTitle(matchOwner, matchSlug))
  const currentUserName = useAppSelector(selectCurrentUsername)
  const isOwnedMatch = matchOwner === currentUserName
  const isPubMode = useAppSelector(selectAudienceFlags).isPub
  const playerVisibility = useAppSelector(selectPlayerVisibility)
  const { isShowGuestPlayers, isShowHostPlayer, isShowUserPlayers } = playerVisibility
  const [isShowAdmin, setIsShowAdmin] = useState(false)
  const currentGamers = useAppSelector(selectCurrentGamers)
  const isHostUserCurrentGamer =
    currentGamers.findIndex(({ gamerId }) => gamerId === currentUserName) >= 0
  const className = cx(PaneClasses[Pane.STATUS], { hasFocus: isCurrFocus, left: true })
  // const onClick = () => {
  //   // console.log(`container: ${PaneClasses[Pane.SETTINGS]}`)
  //   dispatch(activateNextPane({ pane: Pane.STATUS }))
  // }
  const onToggleGuestPlayers = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowGuestPlayers: !isShowGuestPlayers,
      })
    )
  }
  const onToggleHostPlayer = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowHostPlayer: !isShowHostPlayer,
      })
    )
  }
  const onToggleOtherPlayers = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowUserPlayers: !isShowUserPlayers,
      })
    )
  }
  const onClose = () => {
    keyBindings.popModal()
    dispatch(modalsSlice.actions.toggleLeaderboardModal(false))
    // if (isWatching) {
    //   dispatch(toggleLeaderboard({ matchOwner, matchSlug, isWatch: false }))
    // }
  }
  const togglePlayersAdmin = () => {
    setIsShowAdmin(!isShowAdmin)
  }
  const getBody = () => {
    if (isShowAdmin) {
      return (
        <GuestPlayers
          compoundMatchSlug={currentMatchSlug}
          isShowSectionHeading={false}
          isShowPlayerIds={isShowHostPlayer}
        />
      )
    }
    return (
      <>
        {!isPubMode && (
          <div className="visibility">
            <CheckboxWithLabel
              label="Guests"
              value={isShowGuestPlayers}
              onChange={onToggleGuestPlayers}
            />
            <CheckboxWithLabel
              label="Others"
              value={isShowUserPlayers}
              onChange={onToggleOtherPlayers}
            />
            <CheckboxWithLabel
              label="Host"
              value={isShowHostPlayer}
              onChange={onToggleHostPlayer}
              disabled={false && isHostUserCurrentGamer} // TODO: make sure no conflict with current blaster?
            />
          </div>
        )}
        <MatchLeaderboard matchSlug={currentMatchSlug} isShowGuestPlayers={isShowGuestPlayers} />
      </>
    )
  }
  return (
    <ModalBackdrop isTop>
      <div className="modalContainer leaderboardModal" ref={onModalContainerRef} tabIndex={0}>
        <CloseIcon onClose={onClose} />
        <div className="modalHeading">
          <button onClick={togglePlayersAdmin}>{isShowAdmin ? 'Players' : 'Leaderboard'}</button>
          <div className="matchTitle">{matchTitle}</div>
          <button className="blastButton" tabIndex={-1} />
        </div>
        <div className={className}>{getBody()}</div>
      </div>
    </ModalBackdrop>
  )
}

export default LeaderboardModal
